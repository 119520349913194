import Head from 'next/head'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import isString from 'lodash/isString'
import capitalize from 'lodash/capitalize'

export interface MetaProps {
  title?: string,
  description?: string
  path?: string
}

const Meta: React.FC<MetaProps> = ({ title = '', description = '' }) => {
  const { query: { color } } = useRouter()
  const productColor = useMemo(() => {
    if (color) {
      const firstColor = isString(color) ? color.split(',')?.[0] : color?.[0]
      return `${capitalize(firstColor)} `
    }
    return ''
  }, [color])

  return (
    <div>
      <Head>
        <title>
          {productColor}
          {title || 'Home Décor Store | Affordable & Modern Furniture | Z Gallerie'}
        </title>
        <meta
          name="description"
          content={description || `Shop affordable home
        décor &amp; stylish, chic furniture at Z Gallerie. Browse our
        collection of modern furniture, bedding, art & more or visit us in store!
        `}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
      </Head>
    </div>
  )
}

export default Meta
